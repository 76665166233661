<template>
  <v-app>
    <v-system-bar class="d-print-none pt-3" height="30" app lights-out>
      <v-toolbar-items class="text-right">
        <v-btn
          large
          rounded
          dark
          href="javascript:window.print()"
          class="title mr-2 rounded"
          color="info"
        >
          <v-icon class="mr-1" color="white" large>mdi-printer</v-icon>พิมพ์
        </v-btn>
      </v-toolbar-items>
    </v-system-bar>

    <div id="exportContent">
      <div class="page">
        <br>
        <div class="text-center">
          <div class="head">
            แบบเสนอรายชื่อคณะกรรมการเตรียมความพร้อมและพัฒนาอย่างเข้ม
            ตำแหน่งครูผู้ช่วย
          </div>
          <div class="head">
            ของข้าราชการครูและบุคลากรทางการศึกษา
            สังกัดสำนักงานคณะกรรมการการอาชีวศึกษา
          </div>
        </div>

        <div class="text-center mt-5 mb-5">
          <hr />
        </div>
<div class="div-indent">
  ข้าพเจ้า ผู้อำนวยการ{{ editmanage_assistant_teacher.college_name }}  ขอเสนอรายชื่อคณะกรรมการเตรียมความพร้อมและพัฒนาอย่างเข้ม
                ตำแหน่งครูผู้ช่วย เพื่อให้คณะกรรมการทำหน้าที่ เป็นพี่เลี้ยง
                โดยให้คำปรึกษา สอนงาน ช่วยเหลือ
                แนะนำการปฏิบัติตนและการปฏิบัติงานให้มีคุณลักษณะในหน้าที่ความรับผิดชอบตามมาตรฐานตำแหน่ง
                ที่ ก.ค.ศ. กำหนด ของข้าราชการ 
</div>
        <!-- <table class="text_j" width="100%">
          <tbody>
            <tr>
              <td width="10%"></td>
              <td width="10%" class="">ข้าพเจ้า</td>
              <td width="30%" class="text-center td_line">
                {{ editmanage_assistant_teacher.collegeinfo_director }}
              </td>
              <td width="50%" class="">
                <span v-if="editmanage_assistant_teacher.collegeinfo_director_type==='1'">รักษาการในตำแหน่ง </span>ผู้อำนวยการ{{ editmanage_assistant_teacher.college_name }}
              </td>
            </tr>
            <tr>
              <td colspan="4">
                ขอเสนอรายชื่อคณะกรรมการเตรียมความพร้อมและพัฒนาอย่างเข้ม
                ตำแหน่งครูผู้ช่วย เพื่อให้คณะกรรมการทำหน้าที่ เป็นพี่เลี้ยง
                โดยให้คำปรึกษา สอนงาน ช่วยเหลือ
                แนะนำการปฏิบัติตนและการปฏิบัติงานให้มีคุณลักษณะในหน้าที่ความรับผิดชอบตามมาตรฐานตำแหน่ง
                ที่ ก.ค.ศ. กำหนด ของข้าราชการ
              </td>
            </tr>
          </tbody>
        </table> -->

        <table class="text_j" width="100%">
          <tbody>
            <tr>
              <td width="5%" class="">ราย</td>
              <td class="text-center td_line">
                {{ editmanage_assistant_teacher.mt_name }}
              </td>
              <td width="10%" class="text-center">สาขาวิชา</td>
              <td class="text-center td_line">
                {{ editmanage_assistant_teacher.name_branch }}
              </td>
            </tr>
          </tbody>
        </table>

        <table class="text_j" width="100%">
          <tbody>
            <tr>
              <td class="td_line">
                {{ editmanage_assistant_teacher.college_name }}
              </td>
              <td width="35%" class="text-center">
                ซึ่งได้รับการบรรจุแต่งตั้ง เมื่อวันที่
              </td>
              <td class="text-center td_line">
                {{
                  editmanage_assistant_teacher.mt_date_app_now
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
              </td>
            </tr>
          </tbody>
        </table>

        <br>
        <div class="font-weight-bold">
          คณะกรรมการประกอบด้วย
        </div>
        <table class="text_jj" width="100%">
          <tbody class="">
            <tr>
              <td width="5%" class="text-center font-weight-bold">1.</td>
              <td width="45%" class="text-left pl-2 font-weight-bold">
                ผู้อำนวยการ{{ editmanage_assistant_teacher.college_name }}
              </td>
              <td class="text-left font-weight-bold">
                ประธานกรรมการ
              </td>
            </tr>
          </tbody>
        </table>

        <table class="text_jj" width="100%">
          <tbody class="">
            <tr>
              <td width="5%" class="text-center font-weight-bold">
                <span>2.</span>
              </td>
              <td width="45%" class="text-left pl-2 font-weight-bold">
                {{
                  editassistantcommittee_2.title_s +
                    editassistantcommittee_2.frist_name +
                    " " +
                    editassistantcommittee_2.last_name
                }}
              </td>

              <td width="50%" class="text-left font-weight-bold">
                <span>กรรมการ (ครูในสถานศึกษา)</span>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="text_jj" width="100%">
          <tbody>
            <tr>
              <td width="5%"></td>
              <td width="10%" class="pl-2">วิทยฐานะ</td>
              <td width="25%" class="text-center td_line">
                {{ editassistantcommittee_2.rang_name }}
              </td>
              <td width="15%" class="pl-2">วุฒิการศึกษา</td>
              <td class="text-center td_line">
                {{ editassistantcommittee_2.ed_abb }}
                {{ editassistantcommittee_2.ed_name }}
              </td>
            </tr>
          </tbody>
        </table>

        <table class="text_jj" width="100%">
          <tbody class="">
            <tr>
              <td width="5%" class="text-center font-weight-bold">
                <span>3.</span>
              </td>
              <td width="45%" class="text-left pl-2 font-weight-bold">
                {{ editassistantcommittee_1.committeeNames }}
              </td>

              <td class="text-left font-weight-bold">
                <span>กรรมการ (ผู้ทรงคุณวุฒิอื่นจากภายนอกสถานศึกษา)</span>
              </td>
            </tr>
          </tbody>
        </table>

        <br />
        <br />

        <table width="100%" class="text_j">
          <tr>
            <td width="50%"></td>
            <td width="50%" class="text-center">
              ลงชื่อ...........................................................
            </td>
          </tr>
          <tr>
            <td></td>
            <td class="text-center">
              (.................................................................)
            </td>
          </tr>
          <tr>
            <td></td>
            <td class="text-center">ผู้รายงานข้อมูล</td>
          </tr>
        </table>

        <br />
        <br />

        <table width="100%" class="text_j">
          <tr>
            <td width="50%"></td>
            <td width="50%" class="text-center">
              ลงชื่อ...........................................................
            </td>
          </tr>
          <tr>
            <td></td>
            <td class="text-center">
              ( {{ editmanage_assistant_teacher.collegeinfo_director }} )
            </td>
          </tr>

          <tr v-if="editmanage_assistant_teacher.collegeinfo_director_type==='1'">
            <td></td>
            <td class="text-center">
              <span v-if="editmanage_assistant_teacher.collegeinfo_director_type==='1'">รักษาการในตำแหน่ง</span>
            </td>
          </tr>

          <tr>
            <td></td>
            <td class="text-center">
              ผู้อำนวยการ{{ editmanage_assistant_teacher.college_name }}
            </td>
          </tr>

          

          <tr>
            <td></td>
            <td class="text-center">
              {{
                date_today_cal
                  | moment("add", "543 years")
                  | moment("D MMMM YYYY")
              }}
            </td>
          </tr>
        </table>

        <br />


        <!-- New Page -->
        <p style="page-break-before: always;">&nbsp;</p>
        <!-- 2 -->
        <br>

        <div align="center" class="pt-10">
          <div class="head">
            แบบเสนอบัญชีรายชื่อผู้ทรงคุณวุฒิอื่นจากภายนอกสถานศึกษา
          </div>
          <div class="head">
            ตามหลักเกณฑ์และวิธีการเตรียมความพร้อมและพัฒนาอย่างเข้ม
            ตำแหน่งครูผู้ช่วย
          </div>
          <div class="head">
            ของข้าราชการครูและบุคลากรทางการศึกษา สำนักงานคณะกรรมการการอาชีวศึกษา
          </div>
          <div class="head">
            ที่ได้รับการบรรจุแต่งตั้งเมื่อวันที่
            {{
              editmanage_assistant_teacher.mt_date_app_now
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </div>
          <div class="head">
            {{ editmanage_assistant_teacher.mt_name }} สาขา
            {{ editmanage_assistant_teacher.name_branch }}
          </div>
          <div class="head">
            สังกัด {{ editmanage_assistant_teacher.college_name }}
          </div>
        </div>

        <br />
        <div class="text-center">
          <hr>
        </div>
        <br />

       
        <table
          width="100%"
          class="text_j"
          v-if="
            assistantcommitteeL2s.assistantcommitteeQualifiedType === 'inside'
          "
        >
          <tr>
            <th class="text-center th pa-1">คำนำหน้า</th>
            <th class="text-center th">ชื่อ – สกุล</th>
            <th class="text-center th">วุฒิการศึกษา</th>
            <th class="text-center th">
              ตำแหน่งปัจจุบัน/ <br />
              ตำแหน่งสุดท้ายก่อนได้รับ <br />
              การเสนอชื่อ
            </th>
          </tr>
          <tr>
            <td class="text-center th">
              {{ assistantcommitteeL2s.assistantcommitteeQualifiedTitles }}
            </td>
            <td class="text-center th pl-1">
              {{
                assistantcommitteeL2s.assistantcommitteeQualifiedFristname +
                  " " +
                  assistantcommitteeL2s.assistantcommitteeQualifiedLastname
              }}
            </td>
            <td class="text-left th pl-1">
              <div class="font-weight-bold">
                ปริญญาตรี
              </div>
              <div>
                {{ assistantcommitteeL2s.assistantcommitteeQualifiedEDSub }}
                {{ assistantcommitteeL2s.assistantcommitteeQualifiedED }}
              </div>
              <div
                v-if="
                  String(
                    assistantcommitteeL2s.assistantcommitteeQualifiedEDMaster
                  ).length > 4
                "
              >
                <div class="font-weight-bold">
                  ปริญญาโท
                </div>
                <div>
                  {{
                    assistantcommitteeL2s.assistantcommitteeQualifiedEDMasterSub
                  }}
                  {{
                    assistantcommitteeL2s.assistantcommitteeQualifiedEDMaster
                  }}
                </div>
              </div>

              <div
                v-if="
                  String(
                    assistantcommitteeL2s.assistantcommitteeQualifiedEDDoctoral
                  ).length > 4
                "
              >
                <div class="font-weight-bold">
                  ปริญญาเอก
                </div>
                <div>
                  {{
                    assistantcommitteeL2s.assistantcommitteeQualifiedEDDoctoralSub
                  }}
                  {{
                    assistantcommitteeL2s.assistantcommitteeQualifiedEDDoctoral
                  }}
                </div>
              </div>
            </td>
            <td class="text-center th pl-1">
              {{ assistantcommitteeL2s.assistantcommitteeQualifiedPosition }}
              <br />
              {{ assistantcommitteeL2s.assistantcommitteeQualifiedCollegeMain }}

              
            </td>
          </tr>
        </table>

        <table
          width="100%"
          class="text_j"
          v-else
        >
          <tr>
            <th width="10%" class="text-center th pa-1">คำนำหน้า</th>
            <th width="20%" class="text-center th">ชื่อ – สกุล</th>
            <th width="30%" class="text-center th">วุฒิการศึกษา</th>
            <th width="20%" class="text-center th">
              ตำแหน่ง/ <br />
              สถานที่ปฏิบัติงาน<br />
              ณ ปัจจุบัน
            </th>

            <th width="20%" class="text-center th">
              ตำแหน่งสุดท้ายก่อน/ <br />
              ได้รับการเสนอรายชื่อ <br />
              คณะกรรมการ ฯ
            </th>
          </tr>
          <tr>
            <td class="text-center th">
              {{ assistantcommitteeL2s.assistantcommitteeQualifiedTitles }}
            </td>
            <td class="text-center th pa-1">
              {{
                assistantcommitteeL2s.assistantcommitteeQualifiedFristname +
                  " " +
                  assistantcommitteeL2s.assistantcommitteeQualifiedLastname
              }}
            </td>
            <td class="text-left th pl-1">
              <div class="font-weight-bold">
                ปริญญาตรี
              </div>
              <div>
                {{ assistantcommitteeL2s.assistantcommitteeQualifiedEDSub }}
                {{ assistantcommitteeL2s.assistantcommitteeQualifiedED }}
              </div>
              <div
                v-if="
                  String(
                    assistantcommitteeL2s.assistantcommitteeQualifiedEDMaster
                  ).length > 4
                "
              >
                <div class="font-weight-bold">
                  ปริญญาโท
                </div>
                <div>
                  {{
                    assistantcommitteeL2s.assistantcommitteeQualifiedEDMasterSub
                  }}
                  {{
                    assistantcommitteeL2s.assistantcommitteeQualifiedEDMaster
                  }}
                </div>
              </div>

              <div
                v-if="
                  String(
                    assistantcommitteeL2s.assistantcommitteeQualifiedEDDoctoral
                  ).length > 4
                "
              >
                <div class="font-weight-bold">
                  ปริญญาเอก
                </div>
                <div>
                  {{
                    assistantcommitteeL2s.assistantcommitteeQualifiedEDDoctoralSub
                  }}
                  {{
                    assistantcommitteeL2s.assistantcommitteeQualifiedEDDoctoral
                  }}
                </div>
              </div>
            </td>
            <td class="text-center th pl-1">
              {{ assistantcommitteeL2s.assistantcommitteeQualifiedPosition }}

              
            </td>

            <td class="text-center th pl-1">
              {{ assistantcommitteeL2s.assistantcommitteeQualifiedETC }}

            </td>
          </tr>
        </table>

        <br />

       <!--  <table width="100%" class="text_j regular14">
         
          <tr>
            <td class="font-weight-bold red--text pr-1">หมายเหตุ</td>
            <td>
              <span class="font-weight-bold"
                >1. ให้พิจารณาวิทยฐานะไม่ต่ำกว่า ชำนาญการพิเศษ</span
              >
               
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <span class="font-weight-bold">
                2. กรณีผู้ทรงคุณวุฒิอื่นจากภายนอกสถานศึกษา</span
              >
              เป็นข้าราชการบำนาญ ต้องไม่เป็นผู้ที่เคยปฏิบัติหน้าที่ ณ
              สถานศึกษาที่ประเมิน
            </td>
          </tr>
        </table> -->
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    ApiKey: "HRvec2021",
    user: [],
    editassistantcommittee_1: [],
    editassistantcommittee_2: [],
    editmanage_assistant_teacher: [],
    assistantcommitteeL2s: []
  }),

  async mounted() {
    await this.sweetAlertLoading();
    await this.assistantcommitteeQuery_1();
    await this.assistantcommitteeQuery_2();
    await this.manage_assistant_teacherQuery();
    await this.assistantcommitteeL2Query();
    Swal.close();
  },

  methods: {
    async assistantcommitteeQuery_1() {
      let result = await this.$http.post("assistantcommittee.php", {
        ApiKey: this.ApiKey,
        assistantcommitteeIDCardTeach: this.mt_id_card,
        assistantcommitteeTime: this.mt_times,
        assistantcommitteeYear: this.mt_years,
        assistantcommitteePositon: "1"
      });
      this.editassistantcommittee_1 = result.data;
      this.editassistantcommittee_1.committeeNames =
        this.editassistantcommittee_1.assistantcommitteeQualifiedTitles +
        this.editassistantcommittee_1.assistantcommitteeQualifiedFristname +
        " " +
        this.editassistantcommittee_1.assistantcommitteeQualifiedLastname;
    },

    async assistantcommitteeQuery_2() {
      let result = await this.$http.post("assistantcommittee.php", {
        ApiKey: this.ApiKey,
        assistantcommitteeIDCardTeach: this.mt_id_card,
        assistantcommitteeTime: this.mt_times,
        assistantcommitteeYear: this.mt_years,
        assistantcommitteePositon: "2"
      });
      this.editassistantcommittee_2 = result.data;
    },

    async manage_assistant_teacherQuery() {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: this.mt_id
      });
      this.editmanage_assistant_teacher = result.data;
    },

    async assistantcommitteeL2Query() {
      let result = await this.$http.post("assistantcommittee.php", {
        ApiKey: this.ApiKey,
        assistantcommitteeTime: this.mt_times,
        assistantcommitteeYear: this.mt_years,
        assistantcommitteeIDCardTeach: this.mt_id_card,
        assistantcommitteePositon: "1"
      });
      this.assistantcommitteeL2s = result.data;
    },

    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },

    Export2Doc(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-16'><title>Export HTML To Doc</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msword"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-word;charset=utf-16," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".doc" : "document.doc";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }

      document.body.removeChild(downloadLink);
    },
    exportToExcel(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Excel</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msexcel"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-excel;charset=utf-8," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".xls" : "document.xls";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
      document.body.removeChild(downloadLink);
    }
  },
  computed: {
    mt_id_card() {
      return this.$route.query.mt_id_card;
    },

    mt_times() {
      return this.$route.query.mt_times;
    },

    mt_years() {
      return this.$route.query.mt_years;
    },

    mt_id() {
      return this.$route.query.mt_id;
    },

    date_today_cal() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      today = yyyy + "-" + mm + "-" + dd;
      return today;
    }
  },
  props: {
    source: String
  }
};
</script>
<style>
body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
}

.u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}
u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.table,
.th,
.td {
  border-bottom: 0.5px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
}

.text_j {
  border-collapse: collapse;

  text-justify: inter-word;
  line-height: 1.25;
}

.text_jj {
  border-collapse: collapse;

  text-justify: inter-word;
  line-height: 1;
}

.text_l {
  border-collapse: collapse;
  text-align: left;
  text-justify: inter-word;
  line-height: 1;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}

td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}

#verticaltext {
  writing-mode: tb-rl;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
}

.div-indent {
  text-indent: 3em;
  line-height: 1.25;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 21cm;
  min-height: 29.7cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 1.5cm;
  padding-right: 1cm;
  padding-bottom: 1.5cm;
  padding-left: 2cm;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
  line-height: 1.25;
}

.regular14 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}
.td_line {
  border-bottom: 1pt dotted black;
}
/*.subpage {
            height: 247mm;
          }*/

.BlackBold10 {
  font-weight: bold;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

@media print {
  .page {
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    padding: initial; 
    padding-top: 1.5cm;
    padding-right: 1cm;
    padding-bottom: 1.5cm;
    padding-left: 2cm;
  }

  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  /* .head {
              font-size: 18px;
              font-weight: bold;
            }
            .regular12 {
              font-size: 12px;
            }
            .blod12 {
              font-size: 12px;
              font-weight: bold;
            }
            .blackRegula8 {
              font-size: 8px;
            } */
  .noprint {
    display: none;
  }

  .gap-10 {
    width: 100%;
    height: 10px;
  }
  .gap-20 {
    width: 100%;
    height: 20px;
  }
  .gap-30 {
    width: 100%;
    height: 30px;
  }
}
</style>
